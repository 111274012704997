<template>
    <v-container fluid tag="section">

        <div class="text-end">
            <v-btn outlined class="font-weight-bold me-2" :to="`/items`">{{ $t('Cancel') }}</v-btn>
            <v-btn color="purple" class="font-weight-bold me-0" @click="onSubmit" :loading="loading" :disabled="loading">{{ $t('Save') }}</v-btn>
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-text class="px-0 py-0">
                        <v-form>
                            <v-container>
                                <v-text-field v-model="item.name" :label="$t('Name')"></v-text-field>
                                <v-textarea v-model="item.desc" :label="$t('Description')"></v-textarea>
                                <v-select v-model="item.company_id" :label="$t('Company')" :items="companies"
                                    item-text="name" item-value="id" @change="onChangeCompany"></v-select>
                                <v-select multiple v-model="item.locations" :label="$t('Locations')" :items="locations"
                                    item-text="name" item-value="id" chips></v-select>
                                <v-select multiple v-model="item.categories" :label="$t('Categories')" :items="categories"
                                    item-text="name" item-value="id" chips></v-select>
                                <v-select v-model="item.operation_id" :label="$t('Operation')" :items="operations"
                                    item-text="name" item-value="id" @change="onChangeOperation" chips></v-select>
                                <v-select v-model="item.provider_id" :label="$t('Provider')" :items="selectedOperationProviders"
                                    item-text="name" item-value="id" @change="onChangeProvider" chips></v-select>
                                <v-card class="mt-0">
                                    <!-- Aftereffects -->
                                    <v-card-text v-if="item.provider_id == 2">
                                        <a v-if="item.urls?.configs?.aftereffects?.template?.file" :href="item.urls.configs.aftereffects.template.file"
                                            target="_blank"
                                            :download="item.configs.aftereffects.template.fileName">
                                            {{ item.configs.aftereffects.template.fileName }}
                                        </a>
                                        <v-file-input v-model="item.configs.aftereffects.template.file" :label="$t('Template File')" truncate-length="25" prepend-icon="mdi-image"
                                            hint="Template file path must be in zip archive" persistent-hint>
                                        </v-file-input>
                                        <v-text-field v-model="item.configs.aftereffects.template.composition" :label="$t('Template Composition')"></v-text-field>
                                        <v-text-field v-model="item.configs.aftereffects.template.outputModule" :label="$t('Template Output Module')" placeholder="H264, AfterCodec etc."></v-text-field>
                                        <v-text-field v-model="item.configs.aftereffects.asset.layerName" :label="$t('Asset Layer Name')"></v-text-field>
                                        <v-text-field v-model="item.configs.aftereffects.asset.composition" :label="$t('Asset Composition')"></v-text-field>
                                    </v-card-text>

                                    <!-- Smtp -->
                                    <v-card-text v-if="item.provider_id == 3">
                                        <v-text-field v-model="item.configs.smtp.sender" :label="$t('Sender')"></v-text-field>
                                        <v-text-field v-model="item.configs.smtp.subject" :label="$t('Subject')" persistent-hint
                                            hint="Insert Variable: {COMPANY_NAME} {LOCATION_NAME}">></v-text-field>
                                        <v-row>
                                            <v-col>
                                                <v-textarea v-model="item.configs.smtp.body" :label="$t('Body')" @input="onChangeSmtpBody()" rows="25" wrap='off' persistent-hint
                                                    hint="Insert Variable: {RECEIVER_EMAIL} {COMPANY_NAME} {LOCATION_NAME} {INPUT_URL} {TRANSACTION_UID}">
                                                </v-textarea>
                                            </v-col>
                                            <v-col>
                                                <span v-html="item.configs.smtp.body"></span>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <!-- Ffmpeg -->
                                    <v-card-text v-if="item.provider_id == 4">
                                        <v-textarea v-model="item.configs.ffmpeg.command" :label="$t('Command')" rows="3" persistent-hint
                                            hint='<p><b>Resize:</b> -i {INPUT} -s 720x480 -c:a copy {OUTPUT}</p>
                                                <p><b>Watermark:</b> -i {ASSET1} -i {INPUT} -filter_complex "[1][0]scale2ref=oh*mdar:ih*0.2[logo][video];[video][logo]overlay=(main_w-overlay_w)/2:(main_h-overlay_h)/2" {OUTPUT}</p>'>
                                            <template v-slot:message="{message, key}">
                                                <!-- Show hint in html https://github.com/vuetifyjs/vuetify/issues/9647 -->
                                                <div v-html="message" :key="key"></div>
                                            </template>
                                        </v-textarea>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left col-2">{{ $t('Alias') }}</th>
                                                        <th class="text-left col-2">{{ $t('Type') }}</th>
                                                        <th class="text-left col-5">{{ $t('Content') }}</th>
                                                        <th class="text-left col-2">{{ $t('Action') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(asset, index) in item.configs.ffmpeg.assets" :key="index">
                                                        <td><v-text-field v-model="asset.alias" placeholder="{ASSET1}"></v-text-field></td>
                                                        <td><v-select v-model="asset.type" :items="provider_config_ffmpeg_types"></v-select></td>
                                                        <td>
                                                            <span v-if="asset.type == 'image'">
                                                                <v-img v-if="item.urls?.configs?.ffmpeg?.assets[index]?.content" :src="item.urls.configs.ffmpeg.assets[index].content" max-height="64" max-width="64"></v-img>
                                                                <v-file-input v-model="asset.content" truncate-length="25" prepend-icon="mdi-image"></v-file-input>
                                                            </span>
                                                            <span v-else-if="asset.type == 'video'">
                                                                <video v-if="item.urls?.configs?.ffmpeg?.assets[index]?.content" :src="item.urls.configs.ffmpeg.assets[index].content" controls height="200" width="200"></video>
                                                                <v-file-input v-model="asset.content" truncate-length="25" prepend-icon="mdi-video"></v-file-input>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <v-btn small color="error" class="mx-2" @click="onDeleteFfmpegAseet(index)">
                                                                <v-icon>mdi-delete</v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <v-btn small color="purple" class="font-weight-bold mt-1" @click="onClickAddFfmpegAseet()">{{ $t('Add asset') }}</v-btn>
                                    </v-card-text>

                                    <!-- ZS Background Remover -->
                                    <v-card-text v-if="item.provider_id == 6">
                                        <v-select v-model="item.configs.zsbackgroundremover.method" :label="$t('Method')" :items="provider_config_zsbackgroundremover_methods"></v-select>
                                    </v-card-text>
                                </v-card>
                                <v-card>
                                    <v-card-title>
                                        {{ $t('Attachments') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left col-3">{{ $t('Type') }}</th>
                                                        <th class="text-left col-7">{{ $t('Body') }}</th>
                                                        <th class="text-left col-2">{{ $t('Action') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(attachment, index) in item.attachments" :key="index" :set="attachmentType = attachmentTypeById(attachment.type_id)">
                                                        <td>
                                                            <span v-if="attachment.id">{{ attachmentType.name }}</span>
                                                            <span v-else>
                                                                <v-select v-model="attachment.type_id" :items="attachment_types" item-text="name" item-value="id">
                                                                </v-select>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="attachmentType.type == 'image'">
                                                                <v-img v-if="attachment.urls?.body" :src="attachment.urls.body" max-height="64" max-width="64"></v-img>
                                                                <v-file-input v-model="attachment.body" :label="$t('Image')" truncate-length="25" prepend-icon="mdi-image"></v-file-input>
                                                            </span>
                                                            <span v-else-if="attachmentType.type == 'text'">
                                                                <v-textarea v-model="attachment.body" :label="$t('Text')"></v-textarea>
                                                            </span>
                                                            <span v-else-if="attachmentType.type == 'string'">
                                                                <v-text-field v-model="attachment.body" :placeholder="attachmentType.placeholder"></v-text-field>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <v-btn small color="error" class="mx-2" @click="onDeleteAttachment(index)">
                                                                <v-icon>mdi-delete</v-icon>
                                                            </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <v-btn small color="purple" class="font-weight-bold mt-2" @click="onClickAddAttachment()">{{ $t('Add attachment') }}</v-btn>
                                    </v-card-text>
                                </v-card>
                                <!-- OPERATION.RENDERTEMPLATE -->
                                <div v-if="item.operation_id == 2">
                                    <v-card>
                                        <v-card-title>
                                            {{ $t('Screen Formats') }}
                                        </v-card-title>
                                        <v-card-text>
                                            <v-select v-model="item.screen_format_id" :label="$t('Screen')" :items="screen_format_types"
                                                item-text="name" item-value="id">
                                                <template v-slot:item="{ item }">
                                                    {{ item.name }} <v-icon>mdi-crop-{{ item.alias }}</v-icon>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    {{ item.name }} <v-icon>mdi-crop-{{ item.alias }}</v-icon>
                                                </template>
                                            </v-select>
                                            <v-autocomplete multiple v-model="item.screen_formats" :label="$t('Other Screens')" :items="templates"
                                                item-text="name" item-value="id">
                                                <template v-slot:item="{ item }">
                                                    {{ item.name }} | {{ item.screenFormat.name }} <v-icon>mdi-crop-{{ item.screenFormat.alias }}</v-icon>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <v-chip>{{ item.name }} | {{ item.screenFormat.name }} <v-icon>mdi-crop-{{ item.screenFormat.alias }}</v-icon></v-chip>
                                                </template>
                                            </v-autocomplete>
                                        </v-card-text>
                                    </v-card>
                                    <v-card>
                                        <v-card-title>
                                            {{ $t('Location') }}
                                        </v-card-title>
                                        <v-card-text>
                                            <v-text-field v-model="item.lat" :label="$t('Latitude')"></v-text-field>
                                            <v-text-field v-model="item.lng" :label="$t('Longitude')"></v-text-field>
                                        </v-card-text>
                                    </v-card>
                                    <!-- Thumb -->
                                    <v-img v-if="item.urls?.thumb" :src="item.urls.thumb" max-height="64" max-width="64"></v-img>
                                    <v-file-input v-model="item.thumb" :label="$t('Image')" truncate-length="25" prepend-icon="mdi-image"></v-file-input>
                                    <!-- Video -->
                                    <video v-if="item.urls?.video" :src="item.urls.video" controls height="200" width="200"></video>
                                    <v-file-input v-model="item.video" :label="$t('Video')" truncate-length="25" prepend-icon="mdi-play-box"></v-file-input>
                                </div>
                                <v-text-field v-model="item.priority" :label="$t('Priority')"></v-text-field>
                                <v-text-field v-model="item.price" :label="$t('Price')"></v-text-field>
                                <v-checkbox v-model="item.is_public" :label="$t('Public')"></v-checkbox>
                                <v-checkbox v-model="item.is_active" :label="$t('Active')"></v-checkbox>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import itemService from '@/services/itemService'
import companyService from '@/services/companyService'

export default {
    data() {
        return {
            loading: false,
            item: {
                company_id: null,
                locations: [],
                categories: [],
                name: null,
                desc: null,
                operation_id: null,
                provider_id: null,
                priority: 1,
                price: 0,
                lat: null,
                lng: null,
                configs: {},
                attachments: [],
                screen_format_id: null,
                screen_formats: [],
                is_public: true,
                is_active: true,
            },
            companies: [],
            templates: [],
            operations: [
                {id: 1, alias: 'removebackground', name: 'Remove Background'},
                {id: 2, alias: 'rendertemplate', name: 'Render Template'},
                {id: 3, alias: 'sendemail', name: 'Send Email'},
            ],
            operation_providers: [
                {id: 1, operation_id: 1, alias: 'removebg', name: 'RemoveBg'},
                {id: 2, operation_id: 2, alias: 'aftereffects', name: 'After Effects'},
                {id: 3, operation_id: 3, alias: 'smtp', name: 'Smtp'},
                {id: 4, operation_id: 2, alias: 'ffmpeg', name: 'Ffmpeg'},
                {id: 5, operation_id: 1, alias: 'photoroom', name: 'Photoroom'},
                {id: 6, operation_id: 1, alias: 'zsbackgroundremover', name: 'ZS Background Remover'},
            ],
            provider_configs: {
                removebg: {},
                aftereffects: {
                    template: {
                        file: null,
                        composition: null,
                        outputModule: null,
                    },
                    asset: {
                        layerName: null,
                        composition: null,
                    }
                },
                ffmpeg: {
                    command: null,
                    assets: [],
                },
                photoroom: {},
                zsbackgroundremover: {
                    method: 'birefnet'
                },
            },
            attachment_types: [
                {id: 1, alias: 'guideimage', name: 'Guide image', type: 'image'},
                {id: 2, alias: 'guidetext', name: 'Guide text', type: 'text'},
                {id: 3, alias: 'sceneeditorbackgroundimage', name: 'Scene editor background image', type: 'image'},
                {id: 4, alias: 'sceneeditorshortfigureimage', name: 'Scene editor short figure image', type: 'image'},
                {id: 5, alias: 'sceneeditoraveragefigureimage', name: 'Scene editor average figure image', type: 'image'},
                {id: 6, alias: 'sceneeditortallfigureimage', name: 'Scene editor tall figure image', type: 'image'},
                {id: 7, alias: 'sceneeditormaskimage', name: 'Scene editor mask image', type: 'image'},
                {id: 8, alias: 'sceneeditorboundingbox', name: 'Scene editor bounding box', type: 'string', placeholder: 'x,y,w,h'},
                {id: 9, alias: 'sceneeditoroutputratio', name: 'Scene editor output ratio', type: 'string', placeholder: '2.5'},
            ],
            screen_format_types: [
                {id: 1, alias: 'square', name: 'Square'},
                {id: 2, alias: 'portrait', name: 'Portrait'},
                {id: 3, alias: 'landscape', name: 'Landscape'},
            ],
            provider_config_ffmpeg_types: ['image', 'video'],
            provider_config_zsbackgroundremover_methods: ['birefnet', 'inspyrenet'],
        }
    },
    async created() {
        if (this.$route.params.id) {
            await this.getItem(this.$route.params.id)
        }
        this.getCompanies()
        this.getTemplates()
    },
    computed: {
        locations() {
            return this.item.company_id ? this.companies.find(i => i.id == this.item.company_id)?.locations : []
        },
        categories() {
            return this.item.company_id ? this.companies.find(i => i.id == this.item.company_id)?.item_categories : []
        },
        selectedOperationProviders() {
            return this.item.operation_id ? this.operation_providers.filter(i => i.operation_id == this.item.operation_id) : []
        }
    },
    methods: {
        async getItem(id) {
            try {
                const result = await itemService.get(id)
                this.item = result.data
                this.item.locations = this.item.locations.map(l => l.id)
                this.item.categories = this.item.categories.map(l => l.id)
                this.item.screen_formats = this.item.screenFormats.map(l => l.child_id)
                this.onChangeProvider()
            } catch (error) {
                this.$dialog.message.error(error.message)
                this.$router.push('/items')
            }
        },
        async getCompanies() {
            try {
                const result = await companyService.getAll({ include_locations: true, include_item_categories: true })
                this.companies = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        async getTemplates() {
            try {
                const result = await itemService.getAll({ operation_alias: 'rendertemplate' })
                this.templates = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
        },
        attachmentTypeById(id){
            return this.attachment_types.find(i => i.id == id)
        },
        onChangeCompany() {
            this.item.locations = []
        },
        onChangeOperation() {
            this.item.provider_id = null
        },
        onChangeProvider() {
            const alias = this.operation_providers.find(i => i.id == this.item.provider_id).alias
            if (!this.item.configs) {
                this.item.configs = this.provider_configs[alias]
            }
            this.item.configs[alias] = {...this.provider_configs[alias], ...this.item.configs[alias]}
        },
        onChangeSmtpBody() {
            this.item.configs.__ob__.dep.notify()
        },
        onClickAddAttachment() {
            this.item.attachments.push({
                id: null,
                type_id: 1,
                body: null,
            })
        },
        onDeleteAttachment(index) {
            this.item.attachments.splice(index, 1)
        },
        onClickAddFfmpegAseet() {
            this.item.configs.ffmpeg.assets.push({
                alias: `{ASSET${this.item.configs.ffmpeg.assets.length + 1}}`,
                type: this.provider_config_ffmpeg_types[0],
                content: null
            })
            this.item.configs.__ob__.dep.notify()
            this.item.urls?.configs?.ffmpeg?.assets?.push({content: null})
        },
        onDeleteFfmpegAseet(index) {
            this.item.configs.ffmpeg.assets.splice(index, 1)
            this.item.configs.__ob__.dep.notify()
            this.item.urls?.configs?.ffmpeg?.assets?.splice(index, 1)
        },
        async onSubmit() {
            this.loading = true
            try {
                if (this.$route.params.id) {
                    await itemService.edit(this.$route.params.id, this.item)
                } else {
                    await itemService.add(this.item)
                }
                this.$dialog.message.success(this.$t('Success'))
                this.$router.push('/items')
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
            this.loading = false
        }
    },
}
</script>
